export const education_years = [
    "kindergarten_p0",
    "kindergarten_p1",
    "kindergarten_p2",
    "kindergarten_p3",
    "kindergarten_p4",
    "kindergarten_p5",
    "primary_1",
    "primary_2",
    "primary_3",
    "primary_4",
    "primary_5",
    "primary_6",
    "eso_1",
    "eso_2",
    "eso_3",
    "eso_4",
    "baccalaureate_1",
    "baccalaureate_2",
    "FP_lower",
    "FP_higher",
    "undergraduate",
    "master",
    "phd",
    "other"
];
