export const student_guardian_relations = [
    'father',
    'mother',
    'grandfather',
    'grandmother',
    'brother',
    'sister',
    'uncle',
    'aunt',
    'cousin',
    'legal_guardian',
    'ss_agent',
    'other'
]